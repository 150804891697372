<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header>
      <template>选择兑换材料</template>
    </Header>
    <div style="padding: 20px">
      <!-- 搜索 -->
      <div class="search">
        <div class="left">
          <input v-model="query.name" type="text" placeholder="输入商品名称" />
        </div>
        <div class="right">
          <div class="btn" @click="search">搜索</div>
        </div>
      </div>
      <div class="info" v-if="$route.query.destory == 1">
        *此材料兑换后将会销毁，请谨慎操作
      </div>
    </div>
    <div class="container" ref="container">
      <div class="def" v-if="!list.length">
        <img src="../../../static/image/hezi2.png" alt="" />
        <p>暂无数据</p>
      </div>
      <div class="list">
        <div
          class="bg"
          v-for="item in list"
          :key="item.id"
          @click="select(item)"
        >
          <div class="item">
            <div class="img">
              <div class="bg" v-if="item.isSelect || item.selected">
                <img
                  style="width: 100px; height: 100px; object-fit: contain"
                  v-if="item.selected"
                  src="../../assets/images/lock.png"
                  alt=""
                />
              </div>
              <img :src="item.goods.listPic" alt="" />
            </div>
            <div class="title">
              <div class="left">{{ item.goodsNumber }}</div>
              <div
                class="right"
                :class="item.isSelect || item.selected ? 'active' : null"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnConfirm" v-if="list.length">
      <div @click="submit">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        pageindex: 1,
        pagesize: 20,
        id: '',
        name: '',
        token: null,
      },
      list: [],
      noMore: false,
      initLoading: true,
    };
  },
  mounted() {
    this.initLoading = true;
    this.query.id = this.$route.query.id;
    this.query.token = this.$route.query.token;
    this.getUserGoodsCompose();
  },
  computed: {
    selectList() {
      const id = this.$route.query.id || '';
      const needGoods = this.$store.state.needGoods || [];
      let idList = [];
      if (needGoods[id] && needGoods[id].id) {
        idList = needGoods[id].id;
      }
      return idList;
    },
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.moreCb);
    }
  },
  methods: {
    submit() {
      const list = (this.list || []).filter((e) => e.isSelect);
      if (!list.length) return this.$toast('请选择材料');
      this.$store.commit('SET_NEEDGOOD', {
        ...this.$store.state.needGoods,
        [this.$route.query.id]: {
          total: list.length,
          id: list.map((e) => e.id),
        },
      });
      this.$router.back();
    },
    moreCb(e) {
      const target = e.target;
      if (target.clientHeight + target.scrollTop >= target.scrollHeight) {
        console.log('到底了');
        if (!this.noMore) {
          this.query.pageindex++;
          this.getUserGoodsCompose('more');
        }
      }
    },
    getSelectIdList() {
      const id = this.$route.query.id || '';
      const needGoods = this.$store.state.needGoods || [];
      let idList = [];
      if (needGoods[id] && needGoods[id].id) {
        idList = needGoods[id].id;
      }
      return idList;
    },
    getNoSelectIdList() {
      const id = this.$route.query.id || '';
      const needGoods = this.$store.state.needGoods || [];
      let idList = [];
      for (const [key, val] of Object.entries(needGoods)) {
        if (key !== id) {
          idList = idList.concat(val.id);
        }
      }
      return idList;
    },
    search() {
      if (!this.query.name) return this.$toast('请输入搜索内容');
      this.query.pageindex = 1;
      this.getUserGoodsCompose();
    },
    select(item) {
      if (item.selected) return;
      const list = this.list.filter((e) => e.isSelect);
      if (list.length >= Number(this.$route.query.number) && !item.isSelect)
        return this.$toast(`选择的藏品不得超过${this.$route.query.number}个`);
      item.isSelect = !item.isSelect;
    },
    async getUserGoodsCompose(isMore) {
      const idList = this.getSelectIdList();
      const noSelectIdList = this.getNoSelectIdList();
      try {
        let cb = this.$api.userGoodsCompose;
        if (this.$route.query.checkGoods == 1) {
          cb = this.$api.userCheckGoodsListCompose;
        }
        const data = await cb(this.query);
        if (data.code === 0) {
          data.data.forEach((e) => {
            this.$set(e, 'isSelect', false);
            if (idList.includes(e.id) || e.isSelect) {
              e.isSelect = true;
            }
            if (noSelectIdList.includes(e.id)) {
              e.selected = true;
            }
          });
          if (isMore) {
            this.list = this.list.concat(data.data || []);
          } else {
            this.list = data.data || [];
          }
          if ((data.data || []).length < this.query.pagesize) {
            this.noMore = true;
          }
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
        this.$nextTick(() => {
          if (this.$refs.container) {
            this.$refs.container.addEventListener('scroll', this.moreCb, true);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 140px;
  overflow: auto;
  padding: 0 20px 50px;
}
.def {
  text-align: center;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
.btnConfirm {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-size: 14px;
    color: #fff;
    background: #0754d3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    height: 40px;
    border-radius: 3px;
  }
}
.search {
  display: flex;
  background: #f8f8f8;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .left {
    display: flex;
    align-items: center;
    flex: 1;
    input {
      font-size: 14px;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
  .btn {
    font-size: 14px;
    color: #999999;
  }
}
.info {
  font-size: 12px;
  color: #a4a5a9;
  margin: 10px 0;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  .bg {
    width: 50%;
    box-sizing: border-box;
    padding: 7px;
    .item {
      box-shadow: 0px 10px 39px 7px rgba(1, 15, 45, 0.05);
      border-radius: 3px;
      overflow: hidden;
      .img {
        position: relative;
        height: 180px;
        .bg {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          background: rgba(98, 98, 98, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 100%;
          vertical-align: middle;
          object-fit: cover;
          height: 100%;
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px;
        .left {
          font-size: 14px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .right {
          font-size: 14px;
          width: 10px;
          height: 10px;
          border: 1px solid #cecece;
          position: relative;
          &.active {
            border: 1px solid #0754d3;
            &::before {
              display: block;
              content: '';
              width: 8px;
              height: 5px;
              border-bottom: 2px solid #0754d3;
              position: absolute;
              left: 50%;
              border-left: 2px solid #0754d3;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}
</style>
